// styles
import styles from './styles.scss';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { ToolEmbed } from 'components/ToolEmbed/ToolEmbed';

// utils
import { buttonClick } from 'components/Button/trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { decodingContent } from 'utils/decodingContent';
import { useSectionId } from 'utils/hooks/use-section-id';
import { isEmpty } from 'utils/is-empty';
import { useTracking as useTracking2 } from 'utils/tracking/track';

export interface CTAButtonProps {
  alignment: string;
  text: string;
  border: string;
  link: any;
  contentIndex: number;
  titleInAnchor: boolean;
}

export function CTAButton({
  alignment,
  text,
  border,
  link,
  contentIndex,
  titleInAnchor,
  ...props
}: Readonly<CTAButtonProps>) {
  const track = useTracking();
  const {trackClick} = useTracking2();
  const sectionId = useSectionId('', '', titleInAnchor, contentIndex);

  if (isEmpty(link)) return null;

  const { type, url, showArrow } = link || {};

  /**
   * Add Button tracking on link click
   * @param {*} e
   */
  const trackOnClick = (e) => {
    if (!isEmpty(e.currentTarget.innerText)) {
      track.trackEvent(buttonClick(e.currentTarget.innerText));
      trackClick({ click_intent: 'cta', click_element: 'button', click_text: e.currentTarget.innerText});
    }
  };

  const borderClass = border ? styles.hideBorder : '';
  const hasIframe = type === 'iframe';

  return (
    <div className="grid-container" id={sectionId}>
      <div className={`grid-x align-${alignment} ${styles.ctaButton} ${borderClass}`}>
        {hasIframe ? (
          <ToolEmbed hasArrow={showArrow} src={url} label={decodingContent(text)} {...props} />
        ) : (
          <CmsLink
            link={link}
            className="button button--white button--background-primary"
            tracking={trackOnClick}
          >
            {decodingContent(text)}
          </CmsLink>
        )}
      </div>
    </div>
  );
}
